/* src/pages/Auth.css */
.auth-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
  }
  
  .auth-form {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .auth-form h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
    text-align: center;
  }
  
  .auth-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .auth-form button {
    width: 100%;
    padding: 10px;
    background-color: #1890ff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .auth-form button:hover {
    background-color: #40a9ff;
  }
  
  .auth-form p {
    text-align: center;
    margin-top: 10px;
  }
  
  .auth-form a {
    color: #1890ff;
    text-decoration: none;
  }
  
  .auth-form a:hover {
    text-decoration: underline;
  }
  .custom-button-padding {
    padding: 16px !important;
  }
/* Auth.css */
.custom-button {
  padding: 16px !important;
  border-radius: 16px;
  width: 100%;
  color: black;
}
/* Auth.css */
.custom-label {
  color: grey; /* Замените на нужный вам цвет */
}
  
@media only screen and (max-width: 767px) {
  .login-background-col {
    display: none;
  }
}
.custom-input .ant-input {
  padding: 16px;

}

.custom-addon {
  padding: 16px;
}

