.form-container {
    transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out;
    max-height: 400px; /* Обычная высота формы */
    overflow: hidden;
  }
  
  .form-container.hidden {
    opacity: 0;
    max-height: 0; /* Скрываем форму, уменьшая её высоту */
    padding: 0; /* Убираем отступы */
  }
  
  .form-container.visible {
    opacity: 1;
    max-height: 400px; /* Восстанавливаем высоту формы */
  }
  