.chat-container {
    display: flex;
    height: 100vh;
    flex-direction: column;
    
}

.chat-list {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 12px;
    border: 1px solid #ddd;
    overflow-y: auto;
}

.chat-create {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    
}

.messages {
    max-height: 60vh;
    min-height: 60vh;
    overflow-y: auto;
    margin-bottom: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    
}

.message-item {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    max-width: 70%;
    min-width: auto;
    font-size: 14px;
    line-height: 1.4;
    display: inline-block;
}

.message-item.you {
    background-color: #dcf8c6;
    align-self: flex-end;
    text-align: right;
}

.message-item.other {
    background-color: #f1f1f1;
    align-self: flex-start;
    text-align: left;
}

.message-content {
    word-wrap: break-word;
}

.message-time {
    font-size: 0.8em;
    color: #888;
    margin-top: 5px;
}

.single-check {
    color: gray;
    margin-left: 5px;
}

.double-checks {
    color: green;
    margin-left: 5px;
}

.read-time {
    margin-left: 5px;
    font-size: 0.8em;
    color: gray;
}

.date-separator {
    text-align: center;
    margin: 10px 0;
    font-weight: bold;
    color: #888;
}

.message-input {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 10px 0;
}

.image-preview {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Отступ снизу, чтобы отделить от поля ввода */
    position: relative;
    align-self: flex-start; /* Выровнять миниатюру по левому краю */
}

.image-preview img {
    max-width: 40px;
    max-height: 40px;
    border-radius: 4px;
    cursor: pointer;
}

.image-preview .anticon-close-circle {
    position: absolute;
    top: -5px;
    left: -5px;  /* Иконка закрытия находится сверху слева */
    font-size: 16px;
    color: red;
    cursor: pointer;
    background-color: white;
    border-radius: 50%;
}


.message-input input {
    flex: 1;
    margin-right: 10px;
    box-sizing: border-box;
}

.no-chat-selected {
    text-align: center;
    color: #888;
    font-size: 18px;
}
.chat-closed-message {
    text-align: center;
}
.chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px; /* Отступ снизу для разделения заголовка и списка чатов */
}

.chat-header h2 {
    margin: 0; /* Убираем отступы у заголовка для лучшего выравнивания */
}

.chat-header button {
    margin-left: 10px; /* Отступ слева для кнопки (если нужно) */
}
.upload-button {
    margin-right: 10px;
}
