.mobile-menu {
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;
  height: 60px;
  background-color: #000;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  z-index: 1000;
  padding: 5px; /* Добавляем внутренний отступ для предотвращения выхода элементов */
  box-sizing: border-box; /* Учитываем padding в размере блока */
}

.mobile-menu a {
  text-align: center;
  color: #8c8c8c;
  font-size: 12px;
  text-decoration: none;
  flex-grow: 1;
  padding: 10px 0;
  border-radius: 10px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: calc(100% / 5 - 10px); /* Ограничиваем ширину, чтобы элементы не выходили за границы */
  margin: 0 2px; /* Добавляем небольшой отступ между элементами */
}

.mobile-menu a.active {
  color: #000;
  background-color: #e0f64b;
  border-radius: 15px;
  padding: 10px; /* Увеличиваем отступы сверху и снизу для активного элемента */
  transform: scale(1.1);
  border-radius: 10px;

}

.mobile-menu a span {
  display: block;
  font-size: 10px;
}

.mobile-menu .anticon {
  font-size: 24px;
}
