.app-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: transparent;
    height: 60px;
    position: relative;
    margin-top: 10px;
  }
  
  .logo,
  .notification-icon {
    display: flex;
    align-items: center;
    justify-content: center; /* Центрирование содержимого */
    background-color: #f0f0f0; /* Светло-серый фон */
    border-radius: 12px; /* Закругленные углы */
    height: 50px; /* Устанавливаем одинаковую высоту контейнеров */
    width: 50px; /* Устанавливаем одинаковую ширину контейнеров */
    border: 1px solid #ddd; /* Обводка */
  }
  
  .logo img {
    max-height: 30px; /* Устанавливаем максимальную высоту для логотипа */
    max-width: 30px;  /* Устанавливаем максимальную ширину для логотипа */
    border-radius: 8px; /* Дополнительное округление для изображения */
  }
  
  .page-title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    font-weight: 500;
    color: #3c3c3c;
  }
  
  .notification-icon .anticon {
    font-size: 24px; /* Размер иконки уведомлений */
    color: #3c3c3c;
  }
  .notification-item {
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
    width: 40vh;
  }
  
  .notification-item:last-child {
    border-bottom: none;
  }
  
  .notification-title {
    font-weight: bold;
    color: #000;
    margin-bottom: 5px;
  }
  

  .notification-date {
    font-size: 12px;
    color: #888;
  }
  .notification-text {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Обрезаем текст после двух строк */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 350px; /* Задайте максимальную ширину блока текста */
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
  }
  